<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="8">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="list"/>  New Group
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            </CCardHeader>
                            <CCardBody>
                                <CForm @submit.prevent="createGroup">
                                    <CRow>
                                        <CCol lg="8">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.group.icon.$model"
                                                :isValid="checkIfValid('icon')"
                                                label="Group Icon" 
                                                type="text" 
                                                autocomplete="off" 
                                                readonly 
                                                v-model="group.icon"
                                            >
                                                <template #prepend-content>
                                                    <CIcon :name="group.icon"/>
                                                </template>
                                                
                                                <template #append>
                                                    <CButton color="primary"  @click="$refs.iconModal.modal = true, emitIconModal()">
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol lg="4">
                                            <label>Visible in all department?</label>
                                            <br>
                                            <CSwitch 
                                                color="primary" 
                                                variant="opposite" 
                                                shape="pill" 
                                                :checked.sync="group.general"
                                                v-bind="labelTxt" />
                                        </CCol>
                                        <CCol lg="6">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.group.name.$model"
                                                :isValid="checkIfValid('name')"
                                                label="Name" 
                                                type="text" 
                                                placeholder="Warehouse" 
                                                autocomplete="off"  
                                                v-model="group.name"
                                                invalidFeedback="Must be more than three(3) characters."
                                                v-tocapitalize
                                                v-nospecialcharacter
                                            />
                                        </CCol>
                                        <CCol lg="6">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.group.path.$model"
                                                :isValid="checkIfValid('path')"
                                                label="Path" 
                                                type="text" 
                                                placeholder="warehouse" 
                                                autocomplete="off"  
                                                v-model="group.path"
                                                readonly
                                            >
                                            
                                                <template #prepend-content>
                                                   /
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol lg="12">
                                            <CTextarea
                                                label="Description"
                                                placeholder="Content..."
                                                autocomplete="off"  
                                                v-model="group.description"
                                                rows="3"
                                                v-tocapitalize
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol col="12" class="text-right">
                                            <CButton 
                                                :disabled="!isValid"
                                                type="submit" 
                                                shape="pill" 
                                                color="primary"
                                            >
                                                <font-awesome-icon icon="save"/> Save
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <IconsModal ref="iconModal"/>
            </div>
        </div>
    </div>
</template>
<script>
import IconsModal from '../../modals/IconsModal'
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'GroupCreate',
    components: {
        IconsModal,
    },
    data () {
        return {
            labelTxt: {
                labelOn: 'Yes',
                labelOff: 'No'
            },
            group: {
                icon: 'cil-folder',
                name: null,
                path: null,
                description: null,
                general: false,
            },
        }
    },
    computed: {
        isValid () { return !this.$v.group.$invalid },
        isDirty () { return this.$v.group.$anyDirty },
    },
    validations: {
        group: {
            icon: { required},
            name: { required, minLength: minLength(3) },
            path: { required },
        }
    },
    mounted() {
        this.$watch(
            "$refs.iconModal.selected",
            (new_value, old_value) => (this.group.icon = new_value)
        )
    },
    created () {
        this.validate();
        this.$emit('activeTab', 1);
    },
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.group[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateGroup:function () {
            if( !this.group.path || !this.group.name ){
                return false;
            } 
            return true;
        },
        emitIconModal: function() {
            this.$emit('show_icons_modal');
        },
        clearForm: function() {
            return {
                icon: 'cil-folder',
                name: null,
                path: null,
                description: null,
            }
        },
        createGroup: function () {
            if(!this.validateGroup()) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to add new group.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        description: this.group.description,
                        general: this.group.general,
                        icon: this.group.icon,
                        name: this.group.name,
                        path: `/${this.group.path}`,
                    }
                    return axios.post('/groups/store', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${this.group.name} has been added.`,
                            })
                            this.group = this.clearForm();
                            this.$v.$reset()
                            this.$v.$touch()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
    },
    watch:  {
        'group.name': function (value) {
            this.group.path = value ? value.replace(/\s+/g, '-').toLowerCase() : ''
        },
    }
}
</script>